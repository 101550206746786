/* You can add global styles to this file, and also import other style files */
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.align-right {
  position: absolute;
  right: 0px;
}

.align-left {
  position: absolute;
  left: 0px;
}

.pointer {
  cursor: pointer;
}

.error-snackbar {
  background-color: white;
  color: black;
}

.suceess-snackbar {
  background-color: white;
  color: black;
  button {
    background: white;
    color: green;
  }
}

.add-btn {
  // background-color: #f5f5f5;
}

.center {
  display: flex;
  align-items: center;
  align-items: center;
}

.custom-btn {
  background-color: #008080;
  color: white;
}

.close-btn {
  background-color: red;
  color: white;
}

.primary-color {
  color: #008080;
}

.close-color {
  color: red;
}

 
.mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #008080 !important;
  color: white;
  +.mat-step-label{
    color: #008080 !important;
  }
}
.mat-step-header .mat-step-icon-selected{
  // background-color: $primary;
  background-color: #008080 !important;
  color: white;
  +.mat-step-label{
    color: #008080 !important;
  }
}

.info-link {
  color: #008080;
  cursor: pointer;
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #008080;
}

.modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #008080;
}

.spinner-div {
  display: flex;
  height: 40vh;
  align-items: center;
  justify-content: center;
}

.hide {
  display: none;
}

.disable-color {
  color: gray
}


.badge {
  background-color: #008080;
  border-radius: 25px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
}